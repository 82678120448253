import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import {
  ColumnType,
  Column,
} from '../../../../SGRE-shared/models/tableConfiguration';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { CartService } from '../../../cart/services/cart.service';
import { ApiService } from '../../../../SGRE-shared/services/api.service';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedCheckoutService } from '../../services/sharedcheckout.service';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { errorCodes } from '../../services/errorCode';
import {
  delay,
  retryWhen,
  filter,
  take,
  takeUntil,
  tap,
  switchMap,
} from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { isNotNullable, isNotUndefined } from '@spartacus/core';
import { AccordionType } from '../../models/checkoutDetails';

declare let bootstrap: any;

@Component({
  selector: 'app-priced-entries-accordion',
  templateUrl: './priced-entries-accordion.component.html',
  styleUrls: ['./priced-entries-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricedEntriesAccordionComponent implements OnInit, OnDestroy {
  @ViewChild('cartModal') cartModal: ElementRef;
  @ViewChild('simulationModal') simulationModal: ElementRef;
  @ViewChild('incotermModal') incotermModal: ElementRef;
  @ViewChild('minimumOrderCartModal') minimumOrderCartModal: ElementRef;

  public columns: Column[];
  public cartsList: Observable<any[]>;
  public message = 'There are no products in the cart';
  public imageUrl = environment.siteUrls.getBackofficeUrl.slice(0, -1);
  public userRole: string;
  public modalInstance: any;
  public currency: any;
  public errorMessage = null;
  public currentTotal: any;
  public minimumOrderAmount: any;
  public isShippingMethodLoading: boolean = false;
  disableShippingMethod = false;
  formData = {
    companyName: '',
    street: '',
    city: '',
    postalCode: '',
    country: {
      name: '',
      isocode: ''
    },
    region: {
      name: '',
      isocode: ''
    },
    contactPerson: '',
    phoneNumber: '',
  };
  public csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Cart Details',
    useBom: true,
    noDownload: false,
    headers: [
      'Part Number',
      'Product Name',
      'Variant',
      'Lead Time',
      'Unit Price',
      'Quantity',
      'Total',
    ],
  };
  public incoterms: { label: string; value: string }[] = [];
  public deliveryModes: any[] = [];
  public deliveryModesQuotation: any[] = [];
  public secondaryTotalPrice: number = 0;
  public addresses: any[] = [];
  public secondaryAddresses: any[] = [];
  public selectedCompany: any;
  public selectedIncoterm: any = null;
  public selectedIncotermQuotation: any = null;
  public selectedAddress: any = null;
  public displayOtherAddressModal: boolean = false;
  public nonQuotationEntries: any[] = [];
  public quotationEntries: any[] = [];
  public cartGroupId: any;
  public selectedModal: string = 'primary';
  private unsubscribe$ = new Subject<void>();
  showSimError: boolean;
  disableIncoterm: boolean = false;
  simErrorMessage: string;
  selectedDeliveryMode: any;
  selectedDeliveryModeQuotation: any;
  countries: any[] = [];
  regions: any[] = [];
  subtotal: any = 0;
  secondarySubtotal: any = 0;
  grandTotal: any = 0;
  secondaryGrandTotal: any = 0;
  taxes: any = 0;
  secondaryTaxes: any = 0;
  transportFee: any = 0;
  secondaryTransportFee: any = 0;
  shippingFee: any = 0;
  secondaryShippingFee: any = 0;
  previousAddressWarehouse: any;
  selectedAddressWarehouse: any;
  disableShippingMethodWarehouse: boolean;
  previousAddressQuotation: any;
  selectedAddressQuotation: any;
  disableSelectedAddressQuotation: boolean;
  disableShippingMethodQuotation: boolean;
  columnsNonQuotation: Column[];
  prev_incotermWarehouse: any;
  prev_incotermQuotation: any;
  isReview: boolean = false;
  componentActive: boolean = true;
  isSubmitClicked: boolean = false;
  isChecked = true;
  address: any;
  addressChangeQuotation: any;
  isQuotation: boolean;
  AccordionCount: number;
  AccordionCountReached: number = 0;

  constructor(
    private cartsService: CartService,
    private globalService: GlobalService,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private sharedCheckoutService: SharedCheckoutService,
    private storageService: StorageService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.handleRoute();
  }

  handleRoute() {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        this.isReview = params['cartGroupId'] && params['review'];
        this.checkRoleAccess();
        this.fetchIncoterms();
        this.getCountries();
        this.sharedCheckoutService.setCheckoutPage(true);
        this.createTableConfiguration();
        this.updateTableToShowHyphens();
        this.checkAndDisableShippingMethod();
        this.handleSubscriptions();

        /** Below if-condition is used for just SMARTEDIT. Please do not remove it */
        if (this.globalService.checkSmartEdit()) {
          this.globalService.loadingSubject.next(true);
        }
        if (this.isReview) {
          /** Order Approval flow */
          this.cartGroupId = params['cartGroupId'];
          // other things are handled within 'handleSubscriptions()'
        } else {
          /** Order Process flow */
          this.fetchData();
          if (this.storageService.cartCode) {
            this.getPrimaryCart(this.storageService.cartCode, AccordionType.WAREHOUSE);
          }
          if (this.storageService.secondaryCartCode) {
            this.getSecondaryCart(this.storageService.secondaryCartCode, AccordionType.QUOTATION);
          }
          // To Handle endless spinner during "both warehouse,quotation products deleted & page reloaded"
          if (!this.storageService.cartCode && !this.storageService.secondaryCartCode && this.storageService.cartId) {
            this.sharedCheckoutService.checkNonQuatationLength(false);
            this.sharedCheckoutService.checkQuotationLength(false);
            this.globalService.loadingSubject.next(false);
          }
        }
      });
  }

  createTableConfiguration() {
    let wareHouseAccordion = [
      { label: 'Part Number', name: 'partNumber', type: ColumnType.link },
      { label: 'Product Name', name: 'productName', type: ColumnType.link },
      { label: 'Variant', name: 'variant', type: ColumnType.text },
      { label: 'ETA', name: 'eta', type: ColumnType.text },
      {
        label: 'Unit Price',
        name: 'unitPrice',
        type: ColumnType.price
      },
      { label: 'Quantity', name: 'quantity', type: ColumnType.quantity },
      { label: 'Total', name: 'total', type: ColumnType.price },
    ];
    let quotationAccordion = [
      { label: 'Part Number', name: 'partNumber', type: ColumnType.link },
      { label: 'Product Name', name: 'productName', type: ColumnType.link },
      { label: 'Variant', name: 'variant', type: ColumnType.text },
      { label: 'Lead Time', name: 'leadTime', type: ColumnType.text },
      {
        label: 'Unit Price',
        name: 'unitPrice',
        type: ColumnType.price,
      },
      { label: 'Quantity', name: 'quantity', type: ColumnType.quantity },
      { label: 'Total', name: 'total', type: ColumnType.price },
    ];
    let deleteColumnObj = {
      label: 'Delete',
      name: 'delete',
      type: ColumnType.delete,
    };
    if (!this.isReview) {
      wareHouseAccordion.push(deleteColumnObj);
      quotationAccordion.push(deleteColumnObj);
    } else {
      if (this.userRole !== 'Purchaser') {
        wareHouseAccordion.push(deleteColumnObj);
      }
    }
    this.columns = quotationAccordion.map((item) => new Column(item));
    this.columnsNonQuotation = wareHouseAccordion.map(
      (item) => new Column(item)
    );
  }

  checkRoleAccess(): void {
    let userRoles = this.storageService.userRoles;
    if (userRoles?.includes('Requester')) {
      this.userRole = 'Requester';
    } else if (userRoles?.includes('Purchaser')) {
      this.userRole = 'Purchaser';
    }
  }

  private updateTableToShowHyphens(): void {
    if (this.quotationEntries) {
      this.quotationEntries.forEach((entry) => {
        entry.unitPrice = '-';
        entry.total = '-';
        entry.leadTime = '-';
      });
      localStorage.setItem('quotationEntries', JSON.stringify(this.quotationEntries));
    }
  }

  private checkAndDisableShippingMethod(): void {
    if (!this.selectedAddressWarehouse || !this.selectedAddressQuotation) {
      this.disableShippingMethodWarehouse = true;
      this.disableShippingMethodQuotation = true;
    } else {
      this.disableShippingMethodWarehouse = false;
      this.disableShippingMethodQuotation = false;
    }
  }

  handleSubscriptions() {
    /** Order Approval flow */
    this.sharedCheckoutService.cartGroupData$
      .pipe(
        filter(isNotNullable),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(response => {
        this.globalService.loadingSubject.next(true);
        this.getReviewCart(response);
      });

    // On Submit button click
    this.sharedCheckoutService.isBtnClick$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data) {
          this.isSubmitClicked = data;
          this.cdr.markForCheck();
        }
      });

    // To capture Simulation error
    this.sharedCheckoutService.showSimulationError
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.showSimError = data;
        this.cdr.detectChanges();
      });
    this.sharedCheckoutService.showSimulationError
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.showSimError = data;
        this.cdr.detectChanges();
        if (this.showSimError) {
          setTimeout(() => {
            this.modalInstance = new bootstrap.Modal(
              this.simulationModal.nativeElement,
              {
                backdrop: 'static',
                keyboard: false,
              }
            );
            this.modalInstance.show();
            this.removeBackdrop();
          }, 2000);
        }
      });
    this.sharedCheckoutService.simulationErrorMessage
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.simErrorMessage = data;
        this.cdr.detectChanges();
      });
  }

  fetchIncoterms(): void {
    this.apiService.getIncoterms()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          // Map the response to your desired format
          this.incoterms = response.incoterms.map((term) => ({
            label: term,
            value: term,
          }));

          // Check if a stored Incoterm is available and valid
          if (!this.selectedIncoterm || !this.incoterms.find(incoterm => incoterm.value === this.selectedIncoterm)) {
            this.selectedIncoterm = this.incoterms.length > 0 ? this.incoterms[0].value : null;
          }
          this.cdr.markForCheck();
        },
        error: (error) => {
          console.error('Error fetching Incoterms', error);
        },
      });
  }

  private getCountries() {
    this.apiService.getCountries()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.countries = res.countries
          .filter((country: any) => country.name)
          .map((country: any) => {
            return { name: country.name, isocode: country.isocode };
          });
      });
  }

  getReviewCart(response: any): void {
    const { carts } = response;
    console.log("checkout response:", carts);
    if (carts?.length > 0) {
      this.AccordionCount = carts.length;
      for (const item of carts) {
        if (item?.entries?.length > 0) {
          item.entries?.some((val) => val.isQuotation)
            ? this.setQuotationData(item, AccordionType.QUOTATION)
            : this.setWarehouseData(item, AccordionType.WAREHOUSE);
        }
      }
    } else { this.AccordionCount = 0; }
  }

  setWarehouseData(data: any, accordionType: string) {
    this.storageService.cartCode = data?.code;
    this.apiService.updateOriginalCartId(data?.originalCartId);
    this.bindTableData(data, accordionType);
    this.selectedIncoterm = this.incoterms.find(
      (incoterm) => incoterm.value === data?.incoterm
    );
    this.prev_incotermWarehouse = data?.incoterm;
    this.fetchShippingAddress();
    if (data?.deliveryAddress) {
      this.selectedAddressWarehouse = data?.deliveryAddress;
      this.apiService.updateAddressChangeQ(data?.deliveryAddress);
    }
    console.log("warehouse shippingMethod:", data?.deliveryMode);
    if (data?.deliveryMode) {
      this.apiService.updateDeliveryModeChange(data?.deliveryMode);
      this.fetchDeliveryMethod({
        cartCode: data?.code,
        cartType: AccordionType.WAREHOUSE,
        reloadCart: false,
        selectedCode: data?.deliveryMode,
        spinnerFlag: true
      });
    } else {
      if (this.AccordionCount > 0) {
        ++this.AccordionCountReached;
        if (this.AccordionCountReached === this.AccordionCount) {
          this.globalService.loadingSubject.next(false);
        }
      } else { this.globalService.loadingSubject.next(false); }
    }
    this.updateCartSummary(data);
    this.cdr.detectChanges();
  }

  setQuotationData(data: any, accordionType: string) {
    this.storageService.secondaryCartCode = data?.code;
    this.apiService.updateOriginalCartId(data?.originalCartId);
    this.bindTableData(data, accordionType);
    this.selectedIncotermQuotation = this.incoterms.find(
      (incoterm) => incoterm.value === data?.incoterm
    );
    this.prev_incotermQuotation = data?.incoterm;
    this.fetchShippingAddress('secondary');
    if (data?.deliveryAddress) {
      this.selectedAddressQuotation = data?.deliveryAddress;
      this.apiService.updateAddress(data?.deliveryAddress);
    }
    console.log("quotation shippingMethod:", data?.deliveryMode);
    if (data?.deliveryMode) {
      this.apiService.updateDeliveryModeChange(data?.deliveryMode);
      this.fetchDeliveryMethod({
        cartCode: data?.code,
        cartType: AccordionType.QUOTATION,
        reloadCart: false,
        selectedCode: data?.deliveryMode,
        spinnerFlag: true
      });
    } else {
      if (this.AccordionCount > 0) {
        ++this.AccordionCountReached;
        if (this.AccordionCountReached === this.AccordionCount) {
          this.globalService.loadingSubject.next(false);
        }
      } else { this.globalService.loadingSubject.next(false); }
    }
    this.disableIncoterm = true;
    this.disableShippingMethodQuotation = true;
    this.disableSelectedAddressQuotation = true;
    this.cdr.detectChanges();
  }

  bindTableData(data: any, accordionType: string) {
    const tableData = this.sharedCheckoutService.getTableData(data);

    if (accordionType === AccordionType.WAREHOUSE) {
      if (tableData?.length > 0) {
        this.nonQuotationEntries = tableData;
        this.nonQuotationEntries.forEach((entry) => {
          entry.codes = entry.codes || [];
          entry.eta = entry.eta
            ? new Date(entry.eta).toISOString().split('T')[0]
            : '';
        });
        this.sharedCheckoutService.checkNonQuatationLength(true);
        this.cdr.detectChanges();
      } else {
        this.nonQuotationEntries = [];
        if (this.storageService.cartCode) { this.clearCartCode(); }
        this.sharedCheckoutService.checkNonQuatationLength(false);
      }
    } else if (accordionType === AccordionType.QUOTATION) {
      if (tableData?.length > 0) {
        this.quotationEntries = tableData;
        this.quotationEntries.forEach((entry) => {
          entry.codes = entry.codes || [];
        });
        this.sharedCheckoutService.checkQuotationLength(true);
        this.cdr.detectChanges();
      } else {
        this.quotationEntries = [];
        if (this.storageService.secondaryCartCode) {
          this.storageService.secondaryCartCode = '';
          localStorage.removeItem(
            AppConstants.SessionStorageKeys.secondaryCartCode
          );
        }
        this.sharedCheckoutService.checkQuotationLength(false);
      }
    }
  }

  private fetchShippingAddress(section?, resetAddress?): void {
    let cartCode =
      section === 'secondary'
        ? this.storageService.secondaryCartCode
        : this.storageService.cartCode;
    if (!this.componentActive) {
      return;
    } else {
      this.globalService.loadingSubject.next(true);
      this.apiService
        .getShippingAddress(cartCode)
        .pipe(
          filter(isNotNullable),
          filter(isNotUndefined),
          takeUntil(this.unsubscribe$)
        )
        .subscribe({
          next: (data) => {
            if (section === 'secondary') {
              this.secondaryAddresses = data?.addresses;
            } else {
              this.addresses = data?.addresses;
            }
            if (
              !this.addresses?.some(
                (val) => val.companyName === 'Other Address'
              )
            ) {
              this.addresses.push({
                companyName: 'Other Address',
                id: 'Other Address',
                value: 'other',
              });
            }
            if (resetAddress) {
              if (this.selectedAddressWarehouse) {
                this.selectedAddressWarehouse = this.addresses.find(
                  (address) =>
                    address.companyName ===
                    this.selectedAddressWarehouse.companyName
                );
              }
              if (this.selectedAddressQuotation) {
                this.selectedAddressQuotation = this.addresses.find(
                  (address) =>
                    address.companyName ===
                    this.selectedAddressQuotation.companyName
                );
              }
            }
            this.cdr.markForCheck();
          },
          error: (error) => {
            console.error(
              'Error fetching shipping addresses: fetchShippingAddress',
              error
            );
            this.globalService.loadingSubject.next(false);
          },
        });
    }
  }

  private fetchDeliveryMethod({
    cartCode,
    cartType = AccordionType.WAREHOUSE,
    reloadCart = true,
    selectedCode = false,
    spinnerFlag = false
  }): void {
    this.apiService.getDeliveryMethod(cartCode)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data) => {
          if (cartType === AccordionType.WAREHOUSE) {
            this.deliveryModes = data?.deliveryModes;
            if (reloadCart) {
              this.getPrimaryCart(this.storageService.cartCode);
            }
            if (selectedCode) {
              this.selectedDeliveryMode = selectedCode;
              this.sharedCheckoutService.changePlaceOrder(true);
              this.cdr.detectChanges();
            }
          } else if (cartType === AccordionType.QUOTATION) {
            this.deliveryModesQuotation = data?.deliveryModes;
            if (reloadCart) {
              this.getSecondaryCart(this.storageService.secondaryCartCode);
            }
            if (selectedCode) {
              this.selectedDeliveryModeQuotation = selectedCode;
              this.sharedCheckoutService.changePlaceOrder(true);
              this.cdr.detectChanges();
            }
          }
          if (spinnerFlag) {
            if (this.AccordionCount > 0) {
              ++this.AccordionCountReached;
              if (this.AccordionCountReached === this.AccordionCount) {
                this.globalService.loadingSubject.next(false);
              }
            } else { this.globalService.loadingSubject.next(false); }
          } else { this.globalService.loadingSubject.next(false); }

        },
        error: (error) => this.globalService.loadingSubject.next(false),
      });
  }

  updateCartSummary(data: any): void {
    if (data.subTotal?.currencyIso) {
      this.currency = data.subTotal?.currencyIso;
      this.subtotal = data.subTotal?.value;
      this.grandTotal = data.totalPriceWithTax?.value;
      this.taxes = data.totalTax?.value;
      this.transportFee = data.transportFee?.value;
      this.shippingFee = data.shippingFee?.value;
    }
  }

  getPrimaryCart(cartCode: string, accordionType?: string): void {
    this.sharedCheckoutService
      .getAccordionData(cartCode)
      .pipe(
        filter(isNotNullable),
        filter(isNotUndefined),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.bindTableData(response, accordionType);
            this.selectedIncoterm = response?.incoterm;
            this.apiService.updateOriginalCartId(response?.originalCartId);
            if (response?.deliveryAddress) {
              this.apiService.updateAddress(response?.deliveryAddress);
              this.selectedAddressWarehouse = response?.deliveryAddress;
            }
            if (response?.deliveryMode) {
              this.apiService.updateDeliveryMode(response?.deliveryMode);
              /** Have doubt on below method */
              this.fetchDeliveryMethod({
                cartCode,
                cartType: AccordionType.WAREHOUSE,
                reloadCart: false,
                selectedCode: response?.deliveryMode,
              });
            }
            this.updateCartSummary(response);
            this.selectedIncoterm = this.incoterms.find(
              (incoterm) => incoterm.value === response?.incoterm
            );
            this.prev_incotermWarehouse = response?.incoterm;
            this.globalService.loadingSubject.next(false);
            this.cdr.detectChanges();
          }
        },
        error: (error) => this.globalService.loadingSubject.next(false),
      });
  }

  getSecondaryCart(secCartCode: string, accordionType?: string) {
    this.globalService.loadingSubject.next(true);
    this.sharedCheckoutService.getAccordionData(secCartCode)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          this.apiService.updateOriginalCartId(response?.originalCartId);
          this.bindTableData(response, accordionType);
          this.selectedIncotermQuotation = response?.incoterm;
          if (response?.deliveryAddress) {
            this.selectedAddressQuotation = response?.deliveryAddress;
            this.apiService.updateAddressChangeQ(response?.deliveryAddress);
          }
          if (response?.deliveryMode) {
            this.apiService.updateDeliveryModeChange(response?.deliveryMode);
            /** Have doubt on below method */
            this.fetchDeliveryMethod({
              cartCode: secCartCode,
              cartType: AccordionType.QUOTATION,
              reloadCart: false,
              selectedCode: response?.deliveryMode,
            });
          }
          this.selectedIncotermQuotation = this.incoterms.find(
            (incoterm) => incoterm.value === response.incoterm
          );
          this.prev_incotermQuotation = response.incoterm;
          this.cdr.detectChanges();
          this.globalService.loadingSubject.next(false);
        },
        error: (error) => {
          console.error('Error fetching delivery mode', error);
          this.globalService.loadingSubject.next(false);
        },
      });
  }

  navigateToPDP(url) {
    this.router.navigate([url]);
  }

  private fetchData(): void {
    if (this.storageService.cartCode) {
      this.fetchShippingAddress('primary', true);
    }
    if (this.storageService.secondaryCartCode) {
      this.fetchSecondaryShippingAddress();
    }
  }

  setQuoAndNonQuo(carts) {
    const quotations = [];
    const nonQuotations = [];
    let isQuotation = false;
    let isNonQuotation = false;
    carts.forEach((cart) => {
      this.transportFee += cart.transportFee.value;
      this.shippingFee += cart.shippingFee.value;
      this.taxes += cart.totalTax.value;
      this.grandTotal += cart.totalPriceWithTax.value;
      this.cdr.detectChanges();
      cart.entries.forEach((entry) => {
        entry.variant = entry.refurbished ? 'R' : 'N';
        if (entry.isQuotation) {
          isQuotation = true;
          entry.productName = entry.product.name;
          entry.partNumber = entry.product.baseProduct;
          entry.unitPrice = entry.basePrice.value;
          entry.currency = entry.basePrice.currencyIso;
          entry.total = entry.totalPrice.value;
          quotations.push(entry);
        }
        if (!entry.isQuotation) {
          isNonQuotation = true;
          entry.productName = entry.product.name;
          entry.partNumber = entry.product.baseProduct;
          entry.unitPrice = entry.basePrice.value;
          entry.currency = entry.basePrice.currencyIso;
          entry.total = entry.totalPrice.value;
          nonQuotations.push(entry);
        }
      });
      if (isQuotation) {
        this.storageService.secondaryCartCode = cart.code;
        this.selectedIncotermQuotation = {
          label: cart.incoterm,
          value: cart.incoterm,
        };
        this.selectedAddressQuotation = cart.deliveryAddress;
        this.selectedDeliveryModeQuotation = cart.deliveryMode;
        this.disableIncoterm = true;
        this.disableShippingMethodQuotation = true;
        this.disableSelectedAddressQuotation = true;
        this.cdr.detectChanges();
      }
      if (isNonQuotation) {
        this.storageService.cartCode = cart.code;
        this.selectedIncoterm = {
          label: cart.incoterm,
          value: cart.incoterm,
        };
        this.selectedAddressWarehouse = cart.deliveryAddress;
        this.selectedDeliveryMode = cart.deliveryMode;
        this.cdr.detectChanges();
      }
      this.apiService.updateOriginalCartId(cart?.originalCartId);
    });
    if (isQuotation) {
      this.fetchShippingAddress('secondary');
      this.fetchDeliveryModes('secondary');
    }
    if (isNonQuotation) {
      this.fetchShippingAddress();
      this.fetchDeliveryModes();
    }
    this.nonQuotationEntries = nonQuotations;
    this.quotationEntries = quotations;
    this.nonQuotationEntries.forEach((entry) => {
      entry.codes = entry.codes || [];
      this.currency = entry.totalPrice?.currencyIso;
      this.subtotal = entry.subTotal?.value;
      entry.eta = entry.eta
        ? new Date(entry.eta).toISOString().split('T')[0]
        : '';
    });
    this.quotationEntries.forEach((entry) => {
      entry.codes = entry.codes || [];
      this.currency = entry.totalPrice?.currencyIso;
      this.subtotal = entry.subTotal?.value;
    });
    this.cdr.detectChanges();
    if (this.quotationEntries?.length) {
      this.sharedCheckoutService.checkQuotationLength(true);
      this.cdr.detectChanges();
    }
    if (this.nonQuotationEntries?.length) {
      this.sharedCheckoutService.checkNonQuatationLength(true);
      this.cdr.detectChanges();
    }
  }

  onCountryChange(event: any) {
    const countryIsoCode = event.value.isocode;
    if (countryIsoCode) {
      this.getRegions(countryIsoCode);
    } else {
      this.regions = [];
    }
  }

  getRegions(countryIsoCode: string) {
    this.globalService.loadingSubject.next(true);
    this.apiService
      .getRegion(countryIsoCode)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.regions = res.regions
          .filter((region: any) => region.name)
          .map((region: any) => {
            return { name: region.name, isocode: region.isocode };
          });
        this.cdr.detectChanges();
        this.globalService.loadingSubject.next(false);
      });
  }

  private fetchDeliveryModes(cartType = 'primary') {
    this.globalService.loadingSubject.next(true);
    let cartCode =
      cartType === 'primary'
        ? this.storageService.cartCode
        : this.storageService.secondaryCartCode;
    this.apiService
      .getDeliveryMethod(cartCode)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data) => {
          if (cartType === 'primary') {
            this.deliveryModes = data.deliveryModes;
          } else {
            this.deliveryModes = data.deliveryModes;
          }
          this.cdr.detectChanges();
          this.globalService.loadingSubject.next(false);
        },
        error: (error) => {
          console.error('Error fetching delivery method', error);
        },
      });
  }

  onShippingMethodChange(accordionType: string): void {
    this.globalService.loadingSubject.next(true);
    let typeVal: AccordionType;
    let cartId: string;
    let shippingMethodVal: string;

    if (accordionType === AccordionType.WAREHOUSE) {
      typeVal = AccordionType.WAREHOUSE;
      cartId = this.storageService.cartCode;
      shippingMethodVal = this.selectedDeliveryMode.code;
      this.apiService.updateDeliveryMode(shippingMethodVal);
    } else if (accordionType === AccordionType.QUOTATION) {
      typeVal = AccordionType.QUOTATION;
      cartId = this.storageService.secondaryCartCode;
      shippingMethodVal = this.selectedDeliveryModeQuotation.code;
      this.apiService.updateDeliveryModeChange(shippingMethodVal);
    }

    this.putDeliveryMethod(cartId, shippingMethodVal, typeVal);
  }

  private putDeliveryMethod(
    cartId: string,
    shippingMethodVal: string,
    accordionType: AccordionType
  ): void {
    this.globalService.loadingSubject.next(true);
    this.apiService
      .putDeliveryMethod(cartId, shippingMethodVal)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          if (accordionType === AccordionType.WAREHOUSE) {
            this.runSimulation();
          } else {
            this.getSecondaryCart(this.storageService.secondaryCartCode);
            this.sharedCheckoutService.changePlaceOrder(true);
          }
        },
        error: (error) => {
          console.error('Error updating delivery method', error);
          this.globalService.loadingSubject.next(false);
        },
      });
  }

  runSimulation() {
    this.sharedCheckoutService.changePlaceOrder(false);
    this.sharedCheckoutService.changeShowError(false);
    if (this.selectedAddressWarehouse && this.selectedDeliveryMode) {
      this.sharedCheckoutService.getAccordionData(this.storageService.cartCode)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (response) => {
            if (response?.simulationErrorMessage) {
              const erCode = response.simulationErrorMessage.split('||')?.at(0).trim();
              this.sharedCheckoutService.changeShowError(true);
              this.sharedCheckoutService.changeErrorMessage(
                errorCodes[erCode] ||
                'Backend Service is down. Please contact administrator.'
              );
              this.globalService.loadingSubject.next(false);
            } else {
              this.sharedCheckoutService.changePlaceOrder(true);
              if (response?.code) {
                this.selectedIncoterm = this.incoterms.find(
                  (incoterm) => incoterm.value === response.incoterm
                );
                if (response?.deliveryAddress) {
                  this.apiService.updateAddress(response?.deliveryAddress);
                  this.apiService.updateDeliveryMode(response?.deliveryMode);
                  this.selectedAddressWarehouse = response?.deliveryAddress;
                }
                this.bindTableData(response, AccordionType.WAREHOUSE);
                this.updateCartSummary(response);
                this.updateNonQuotationEntries(response);
                this.globalService.loadingSubject.next(false);
                this.cdr.detectChanges();
              }
            }
          },
          error: (error) => this.globalService.loadingSubject.next(false),
        });
    }
  }

  /** Have doubt on below method */
  private updateNonQuotationEntries(response: any): void {
    if (response && this.nonQuotationEntries?.length > 0) {
      this.nonQuotationEntries.map((item, index) => {
        item.eta = response?.entries[index]?.eta?.split('T')[0] || 'NA';
      });
      this.sharedCheckoutService.checkNonQuatationLength(true);
      this.cdr.detectChanges();
    } else {
      console.warn('No deliveryOrderGroups found in response');
    }
  }

  public onAddressChangeWarehouse(shippingAddressVal: any): void {
    this.previousAddressWarehouse = this.selectedAddressWarehouse;
    this.apiService.updateAddress(shippingAddressVal);
    this.cdr.detectChanges();

    if (shippingAddressVal.companyName === 'Other Address') {
      // this.disableShippingMethodWarehouse = true;
      // this.selectedDeliveryMode = null;
      this.selectedAddressWarehouse = { companyName: 'Other Address' };
      setTimeout(() => {
        this.openOtherAddressModalPriced();
      });
    } else {
      this.globalService.loadingSubject.next(true);
      this.disableShippingMethodWarehouse = false;

      this.isShippingMethodLoading = true;

      this.apiService
        .putShippingAddress(this.storageService.cartCode, shippingAddressVal.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (response) => {
            this.fetchDeliveryMethod({
              cartCode: this.storageService.cartCode,
              cartType: AccordionType.WAREHOUSE,
              reloadCart: true,
            });

            this.isShippingMethodLoading = false;
            this.globalService.loadingSubject.next(false);
          },
          error: (error) => {
            console.error('Error updating address', error);
            this.globalService.loadingSubject.next(false);
            this.isShippingMethodLoading = false;
          },
        });
    }

    this.apiService.address$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((address) => (this.address = address));

    this.runSimulation();
  }

  public handleAddressChangeQuotation(selectedAddressQuotation: any): void {
    if (selectedAddressQuotation.companyName === 'Other Address') {
      this.disableShippingMethodQuotation = true;
      this.selectedDeliveryModeQuotation = null;
      this.selectedAddressQuotation = { companyName: 'Other Address' };
      setTimeout(() => {
        this.openOtherAddressModalQuotation();
      });
    } else {
      this.globalService.loadingSubject.next(true);
      this.disableShippingMethodQuotation = false;
      this.isShippingMethodLoading = true;
      this.sharedCheckoutService.updateShippingAddress(this.storageService.secondaryCartCode, selectedAddressQuotation.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (response) => {
            this.fetchDeliveryMethod({
              cartCode: this.storageService.secondaryCartCode,
              cartType: AccordionType.QUOTATION,
              reloadCart: true,
            });
            this.globalService.loadingSubject.next(false);
            this.isShippingMethodLoading = false;
          },
          error: (error) => {
            console.error('Error updating address', error);
            this.globalService.loadingSubject.next(false);
            this.isShippingMethodLoading = false;
          },
        });
    }
    this.apiService.addressChangeQuotation$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => (this.addressChangeQuotation = data));
    this.runSimulation();
  }

  private fetchSecondaryShippingAddress(): void {
    this.globalService.loadingSubject.next(true);
    this.apiService.getShippingAddress(this.storageService.secondaryCartCode)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data) => {
          if (data?.addresses) {
            this.secondaryAddresses = data.addresses;
            this.secondaryAddresses.push({
              companyName: 'Other Address',
              value: 'other',
            });
            if (this.selectedAddressWarehouse) {
              this.selectedAddressWarehouse = this.addresses.find((address) => address.companyName === this.selectedAddressWarehouse.companyName);
            }
            if (this.selectedAddressQuotation && this.selectedAddressQuotation.companyName !== 'Other Address') {
              this.selectedAddressQuotation = this.addresses.find((address) => address.companyName === this.selectedAddressQuotation.companyName);
            }
          } else {
            console.error('No addresses found in the response.');
            this.globalService.loadingSubject.next(false);
          }
        },
        error: (error) => {
          console.error(
            'Error fetching shipping addresses: fetchSecondaryShippingAddress',
            error
          );
          this.globalService.loadingSubject.next(false);
        },
      });
  }

  public openOtherIncotermModal(): void {
    this.selectedModal = 'primary';
    this.modalInstance = new bootstrap.Modal(this.incotermModal.nativeElement);
    this.modalInstance.show();
  }

  public openSecondaryOtherIncotermModal(): void {
    this.selectedModal = 'secondary';
    this.modalInstance = new bootstrap.Modal(this.incotermModal.nativeElement);
    this.modalInstance.show();
  }

  public openOtherAddressModalPriced(): void {
    if (this.selectedAddressWarehouse?.companyName === 'Other Address') {
      // this.selectedAddressWarehouse = null;
      this.modalInstance = new bootstrap.Modal(this.cartModal.nativeElement);
      this.modalInstance.show();
    }
  }

  public openOtherAddressModalQuotation(): void {
    if (this.selectedAddressQuotation?.companyName === 'Other Address') {
      this.selectedAddressQuotation = null;
      this.modalInstance = new bootstrap.Modal(this.cartModal.nativeElement);
      this.modalInstance.show();
    }
  }

  /** Have doubt on below method */
  public onAddressChangePriced(event: any): void {
    if (event.value === 'other') {
      this.displayOtherAddressModal = true;
    } else {
      this.selectedAddressWarehouse = event.value;
    }
  }

  /** Have doubt on below method */
  public onAddressChangeQuotation(event: any): void {
    if (event.value === 'other') {
      this.displayOtherAddressModal = true;
    } else {
      this.selectedAddressQuotation = event.value;
    }
  }

  /** Have doubt on below method */
  public removeAllParts(): void {
    this.apiService.removeCart(this.cartGroupId, this.storageService.cartCode)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (success) => {
          this.globalService.loadingSubject.next(true);
          this.cartsService.getCartsList();
          this.cdr.detectChanges();
          this.globalService.getCartsListByLegalEntity();
        },
        error: (error) => {
          this.globalService.loadingSubject.next(false);
        },
      });
    this.quotationEntries = [];
    this.cdr.detectChanges();
  }

  public deleteCart(entryNumber, accordionType: string): void {
    this.globalService.loadingSubject.next(true);
    let cartCode =
      accordionType === AccordionType.WAREHOUSE
        ? this.storageService.cartCode
        : this.storageService.secondaryCartCode;
    this.cartsService
      .deleteCart(entryNumber, cartCode)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          if (accordionType === AccordionType.WAREHOUSE) {
            this.getPrimaryCart(this.storageService.cartCode, accordionType);
          } else if (accordionType === AccordionType.QUOTATION) {
            this.getSecondaryCart(
              this.storageService.secondaryCartCode,
              accordionType
            );
          }
        },
        error: () => {
          this.globalService.loadingSubject.next(false);
        },
      });
  }

  public updateQty(data, accordionType: string) {
    const entryNumber = data.index;
    const specificCartCode =
      accordionType === AccordionType.WAREHOUSE
        ? this.storageService.cartCode
        : this.storageService.secondaryCartCode;
    const qty = data.formArray[entryNumber]?.quantity;

    this.globalService.loadingSubject.next(true);
    this.cartsService.updateQty(entryNumber, qty, specificCartCode)
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            tap((error) => {
              if (
                error.status === 500 &&
                error.error.code === 'DEADLOCK_VICTIM'
              ) {
                console.error('Deadlock occurred, retrying...');
              } else {
                throw error;
              }
            }),
            delay(1000),
            take(3)
          )
        ),
        switchMap(() =>
          this.sharedCheckoutService.getAccordionData(specificCartCode)
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.bindTableData(response, accordionType);

            // Check if it's the WAREHOUSE accordion and perform additional updates
            if (accordionType === AccordionType.WAREHOUSE) {
              this.selectedIncoterm = this.incoterms.find(
                (incoterm) => incoterm.value === response.incoterm
              );
              if (response?.deliveryAddress) {
                this.apiService.updateAddress(response?.deliveryAddress);
                this.apiService.updateDeliveryMode(response?.deliveryMode);
                this.selectedAddressWarehouse = response?.deliveryAddress;
              }
              this.updateCartSummary(response);
              this.updateNonQuotationEntries(response);
              // After receiving the response from the API
              const currentTotal = response.subTotal.value;
              const minimumOrderAmount =
                response.legalEntity?.minOrderAmount || 0;

              this.sharedCheckoutService.setCurrentTotal(currentTotal);
              this.sharedCheckoutService.setMinimumOrderAmount(
                minimumOrderAmount
              );
              this.currentTotal = currentTotal;
              this.minimumOrderAmount = minimumOrderAmount;
              // Optional: Show modal if the minimum order amount is not met
              if (currentTotal < minimumOrderAmount) {
                this.modalInstance = new bootstrap.Modal(
                  this.minimumOrderCartModal.nativeElement
                );
                this.modalInstance.show();
              }
            }
            this.globalService.loadingSubject.next(false);
            this.cdr.detectChanges();
          }
        },
        error: (error) => {
          console.error('Error updating quantity:', error);
          this.globalService.loadingSubject.next(false);
        },
      });
  }

  navigateToProducts(): void {
    if (this.modalInstance) {
      this.modalInstance.hide();
      this.removeBackdrop();
    }
    this.router.navigate([AppConstants.routeUrls.plp]);
  }

  private removeBackdrop(): void {
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
      backdrop.remove();
    }
    document.body.classList.remove('modal-open');
    document.body.style.overflow = 'auto';
  }

  public moveToQuotationEntries(): void {
    let legalEntityId = this.storageService.defaultLegalEntity?.uid;
    this.disableShippingMethodQuotation = true;
    this.selectedDeliveryMode = null;
    const showHyphen = true;
    if (this.selectedModal === 'primary' && this.storageService.cartCode && this.selectedIncoterm?.value) {
      this.apiService.putIncoterm(this.storageService.cartCode, this.selectedIncoterm.value, legalEntityId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (response) => {
            if (!this.storageService.secondaryCartCode) {
              this.storageService.secondaryCartCode = this.storageService.cartCode;
            }
            if (this.quotationEntries) {
              this.quotationEntries = [...this.quotationEntries, ...this.nonQuotationEntries];
            } else {
              this.quotationEntries = [...this.nonQuotationEntries];
            }
            this.nonQuotationEntries = [];
            this.cdr.detectChanges();
            this.getSecondaryCart(this.storageService.secondaryCartCode);
            this.fetchSecondaryShippingAddress();
            this.clearCartCode();
            if (showHyphen) {
              this.updateTableToShowHyphens();
            }
          },
          error: (error) => {
            console.error('Error updating incoterm', error);
          },
        });
    } else if (this.selectedModal === 'secondary' && this.storageService.secondaryCartCode && this.selectedDeliveryModeQuotation?.value) {
      this.apiService.putIncoterm(this.storageService.secondaryCartCode, this.selectedDeliveryModeQuotation.value, legalEntityId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (response) => {
            this.quotationEntries = [...this.quotationEntries, ...this.nonQuotationEntries];
            this.nonQuotationEntries = [];
            this.cdr.detectChanges();
            if (showHyphen) {
              this.updateTableToShowHyphens();
            }
          },
          error: (error) => {
            console.error('Error updating incoterm', error);
          },
        });
    } else if (this.selectedModal === 'secondary' && this.storageService.secondaryCartCode && this.selectedIncotermQuotation?.value) {
      this.prev_incotermQuotation = this.selectedIncotermQuotation?.value;
    } else {
      console.error('Cart ID or selected Incoterm is not available');
    }
  }

  removeAllPartsFromWarehouse(): void {
    this.globalService.loadingSubject.next(true);
    this.apiService
      .removeCart(
        this.storageService.checkoutCartGroupId,
        this.storageService.cartCode
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          this.nonQuotationEntries = [];
          this.clearCartCode();
          this.cdr.detectChanges();
          this.globalService.loadingSubject.next(false);
          if (
            this.quotationEntries.length === 0 &&
            this.nonQuotationEntries.length === 0
          ) {
            this.redirectToPLP();
          }
        },
        error: (error) => {
          console.error('Error removing items from warehouse:', error);
          this.globalService.loadingSubject.next(false);
        },
      });
  }

  removeAllPartsFromQuotation(): void {
    this.globalService.loadingSubject.next(true);
    this.apiService.removeCart(this.storageService.checkoutCartGroupId, this.storageService.secondaryCartCode)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          // Clear quotation entries and update state
          this.quotationEntries = [];
          this.clearSecondaryCartCode();
          // Ensure the application knows that the quotation items have been removed
          this.sharedCheckoutService.checkQuotationLength(false); // Ensure this service is notified of the state change
          this.cdr.detectChanges();
          this.globalService.loadingSubject.next(false);
          // If both quotation and warehouse carts are empty, redirect to PLP
          if (this.quotationEntries.length === 0 && this.nonQuotationEntries.length === 0) {
            this.redirectToPLP();
          }
        },
        error: (error) => {
          console.error('Error removing items from quotation:', error);
          this.globalService.loadingSubject.next(false);
        },
      });
  }

  public redirectToPLP(): void {
    this.router.navigate([AppConstants.routeUrls.plp]);
  }

  onSubmit(form: NgForm) {
    /** Need to verify the Warehouse+Quotation behavior separately */
    if (form.valid) {
      const addressData = {
        companyName: this.formData.companyName,
        street: this.formData.street,
        city: this.formData.city,
        postalCode: this.formData.postalCode,
        country: { isocode: this.formData.country.isocode },
        region: { isocode: this.formData.region.isocode },
        contactPerson: this.formData.contactPerson,
        phoneNumber: this.formData.phoneNumber,
      };
      if (!this.storageService.secondaryCartCode) {
        this.storageService.secondaryCartCode = this.storageService.cartCode;
      }
      // Call the API to save the custom address
      this.apiService.otherAddress(this.storageService.cartCode, addressData)
        .pipe(
          // switchMap(() => this.sharedCheckoutService.updateShippingAddress(this.storageService.secondaryCartCode, 'Other Address')),
          takeUntil(this.unsubscribe$)
        )
        .subscribe({
          next: (response) => {
            this.modalInstance.hide();
            /** Once the address is saved, convert the cart to a quotation cart
             *  Ensure the "Other Address" is selected after conversion
             */
            this.updateQuotationEntries();
          },
          error: (error) => {
            console.error('Error saving custom address:', error);
          },
        });
    }
  }

  /** Have doubt on below method */
  updateQuotationEntries() {
    this.disableShippingMethodQuotation = true;
    this.showSimError = null;
    this.selectedDeliveryModeQuotation = null;
    this.selectedDeliveryMode = null;
    const showHyphen = true;
    if (this.quotationEntries) {
      this.quotationEntries = [...this.quotationEntries, ...this.nonQuotationEntries];
    } else {
      this.quotationEntries = [...this.nonQuotationEntries];
    }
    this.nonQuotationEntries = [];
    this.getSecondaryCart(this.storageService.secondaryCartCode);
    this.fetchSecondaryShippingAddress();
    this.selectedAddressQuotation = { companyName: 'Other Address' };
    this.sharedCheckoutService.checkQuotationLength(true);
    this.apiService.updateAddressChangeQ(true);
    this.sharedCheckoutService.checkNonQuatationLength(false);
    this.clearCartCode();
    this.cdr.detectChanges();
    if (showHyphen) {
      this.updateTableToShowHyphens();
    }
  }

  /** Have doubt on below method */
  handleStateUpdate(cartCode: string, form: NgForm) {
    setTimeout(() => {
      this.disableShippingMethodQuotation = false;
      this.cdr.detectChanges();
      this.clearCartCode();
      this.resetForm(form);
      if (this.shouldShowHyphen()) {
        this.updateTableToShowHyphens();
      }
    }, 1000);
  }

  shouldShowHyphen(): boolean {
    return true;
  }

  resetForm(form: NgForm) {
    this.formData = {
      companyName: '',
      street: '',
      city: '',
      postalCode: '',
      country: {
        name: '',
        isocode: ''
      },
      region: {
        name: '',
        isocode: ''
      },
      contactPerson: '',
      phoneNumber: '',
    };

    if (form) {
      form.resetForm();
    }

    this.regions = [];
  }

  cancelQuotationEntries() {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        if (params['cartGroupId'] && params['review']) {
          this.selectedIncoterm = this.incoterms.find(
            (incoterm) => incoterm.value === this.prev_incotermWarehouse
          );
          /** Have doubt on below line */
          this.disableIncoterm = true;
        } else {
          this.selectedIncoterm = this.incoterms.find(
            (incoterm) => incoterm.value === this.prev_incotermWarehouse
          );
          this.selectedIncotermQuotation = this.incoterms.find(
            (incoterm) => incoterm.value === this.prev_incotermQuotation
          );
        }
      });
    this.cdr.detectChanges();
  }

  clearCartCode() {
    this.storageService.cartCode = '';
    localStorage.removeItem(AppConstants.SessionStorageKeys.cartCode);
  }

  clearSecondaryCartCode() {
    this.storageService.secondaryCartCode = '';
    localStorage.removeItem(AppConstants.SessionStorageKeys.secondaryCartCode);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.componentActive = false;
    this.clearCartCode();
    this.clearSecondaryCartCode();
    this.address = null;
    this.addressChangeQuotation = null;
    this.sharedCheckoutService.clearCheckoutData();
    this.globalService.clearMessagesOnDestroy();
  }
}
